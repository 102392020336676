<template>
  <div class="side-bar" ref="sideBar">
    <div class="side-item" :class="{ 'cur': item.pageUrl === $route.path }" v-for="(item,index) in sideBarList" :key="index">
      <router-link :to="{ path: item.pageUrl }" class="side-item-a">
        <span class="side-icon">
          <b class="icon-img" :style="{ 'background': 'url(' + require('../assets/images/' + item.icon) + ') no-repeat center center'}"></b>
          <b class="icon-bg"></b>
        </span>
        <span class="side-name">{{ item.name }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SideBar',
  data: function() {
    return {
      sideBarList: [
        {
          'icon': 'home.png',
          'name': '首页',
          'pageUrl': '/'
        },
        {
          'icon': 'client.png',
          'name': '客户端',
          'pageUrl': '/client'
        },
        {
          'icon': 'map.png',
          'name': '地图',
          'pageUrl': '/map'
        },
        {
          'icon': 'digitalMall.png',
          'name': '星球商城',
          'pageUrl': '/digitalMall'
        }
      ]
    }
  },
  mounted(){
    var that = this
    eventBus.$on('isShowMobileNav', function(val) {
      if (val === true) {
        that.$refs.sideBar.classList.add('on')
      } else {
        that.$refs.sideBar.classList.remove('on')
      }
    })
  },
  methods: {
  }
}
</script>
