<template>
  <div id="header" class="header">
    <div class="navbar">
      <div class="logo" @click="goHome()">
        <img :src="logo" alt="" title="" />
      </div>
      <div class="navbar-right">
        <span class="nav-icon" @click="mobileBar()"></span>
        <div class="mobile-logo">
          <img :src="logo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Header',
  data: function() {
    return {
      logo: require('../assets/images/logo.png'),
      language: '简中',
      loginName: '登录',
      isCheck: false,
      languageList: [
        {
          'index': 0,
          'content': 'EN'
        },
        {
          'index': 1,
          'content': 'KR'
        },
        {
          'index': 2,
          'content': '简中'
        }
      ],
      isShowNav: false,
      pathParame: []
    }
  },
  mounted(){

  },
  computed: mapGetters(['isLoign', 'eth', 'dv']),
  methods: {
    changeLanguage() {
      this.isCheck = !this.isCheck
    },
    mobileBar() {
      this.isShowNav = !this.isShowNav
      eventBus.$emit('isShowMobileNav', this.isShowNav)
    },
    goHome(){
      this.$router.push('/')
    }
  }
}
</script>
