<template>
  <div class="foot">

    <div class="footer">
      <div class="wrapper footer-box">
        <div class="footer-logo">
          <img :src="logo" />
        </div>
        <div class="footer-nav">
          <h4>导航</h4>
          <div class="footer-nav-list">
            <ul class="footer-nav1 mb10">
              <li v-for="(item,index) in footNavList" :key="index">
                <router-link :to="{ path: item.pageUrl }" class="side-item-a">
                  {{ item.name }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer-copyright ace-row row-middle row-center">
        <p>Copyright © 2022 DV101 版权所有</p>
        <div style="margin: 0 10px"><a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index" style="text-decoration:none; color: #888;">京ICP备2022016486号-1</a></div>
        <div>
          <a class="ace-row row-middle" target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo" style="text-decoration:none;">
            <img src="../assets/images/beian.png" style="float:left;"/>
            <p style="float:left;margin: 0px 0px 0px 5px; color:#939393;">京公网安备 11011202004081号</p>
          </a>
        </div>
        <div style="margin: 0 0 0 10px;"><a target="_blank" href="http://www.cac.gov.cn/2023-02/21/c_1678614564468791.htm" style="text-decoration:none; color: #888;">京网信备11011222606097080019号</a></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data: function() {
    return {
      logo: require('../assets/images/logo.png'),
      footNavList: [
        {
          'pageUrl': '/',
          'name': '首页'
        },
        {
          'pageUrl': '/client',
          'name': '客户端'
        },
        {
          'pageUrl': '/map',
          'name': '地图'
        },
        {
          'pageUrl': '/digitalMall',
          'name': '星球商城'
        }
      ]
    }
  },
  methods: {
  }
}
</script>
